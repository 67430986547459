import React from "react";

import "./Slavery.css";

/***************************************************************************************
 *
 * Company Statements Policy
 *
 ***************************************************************************************/

export const SlaveryStatement = ({ className }) => {
    return (
        <div className={"slavery " + (className ? className : "")}>
            <h2>Modern Slavery Statement</h2>
            <div class="row">
                <div>
                    This statement has been published in accordance with the
                    Modern Slavery Act 2015. It sets out the Company’s
                    commitment to prevent modern slavery and human trafficking
                    in its business and supply chains.
                </div>
            </div>
        </div>
    );
};

export const SlaveryStance = ({ className }) => {
    return (
        <div className={"slavery " + (className ? className : "")}>
            <h2>Our Stance on Modern Slavery</h2>
            <div>
                As a Company and the way we operate, we are committed to
                ensuring that there is no human trafficking or modern slavery in
                any part of our business or in our supply chain. It is important
                that people working in our business, and for our suppliers, work
                in safe, fair and legal conditions, and we will make every
                effort to ensure that we act ethically and with integrity in all
                of our business relationships. If employees have concerns in how
                we conduct our business, we have a Speak Up policy where
                concerns and breaches can be reported.
            </div>
        </div>
    );
};

export const SlaveryEmployees = ({ className }) => {
    return (
        <div className={"slavery " + (className ? className : "")}>
            <h2>Employees</h2>
            <div>
                We have a long-standing policy that we do not use or accept
                forced, bonded or involuntary prison labour or child labour; nor
                demand deposits or hold onto our workers’ identity papers, or
                work with businesses that do. We only work with people who
                choose to work freely, and we respect the right to equal
                opportunity, freedom of association and collective bargaining.
                Our working practices respect and uphold all human rights, and
                we develop our employees through providing training and
                development programmes, as well as providing free English
                language courses.
            </div>
        </div>
    );
};

export const SlaverySupplyChain = ({ className }) => {
    return (
        <div className={"slavery " + (className ? className : "")}>
            <h2>Supply Chains</h2>
            <div>
                Our supply chains include a network of suppliers across
                different countries which mainly include goods and services for
                use in an office environment. We recognise there is complexity
                of the supply chains that extends beyond our immediate
                suppliers, meaning that the chain is likely to have a global
                reach.
            </div>
        </div>
    );
};

export const SlaveryFurtherSteps = ({ className }) => {
    return (
        <div className={"slavery " + (className ? className : "")}>
            <h2>Further Steps</h2>
            <div>
                <p>
                    In light of the Modern Slavery Act, and to ensure there is
                    no slavery or human trafficking in our business or supply
                    chains, we intend to take the following further steps in our
                    next financial year to prevent slavery and human
                    trafficking:
                </p>
                ■ Develop a system to track the number of suppliers evaluated at
                pre-qualification stage for compliance with the Modern Slavery
                Act 2015;
                <br />
                <br />■ Provide training and awareness to relevant stakeholders.
            </div>
        </div>
    );
};

